<template>
    <div class="page-content">
        <div class="grid">
            <div class="page-list-body">
                <div class="row no-gutters page-list-header">
                    <div class="col l-6 m-6 c-12">
                        <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Dự toán làm hàng</span></h4>
                    </div>
                    <div class="col l-6 m-6 c-12">
                       
                    </div>
                </div>
                <div class="form-body">
                    <div class="wrapper">
                        <div class="row no-gutters">
                            <div class="col l-4 m-4 c-12">
                                <div class="left">
                                    <img src="../../../assets/images/logo.png" />
                                </div>
                            </div>
                            <div class="col l-8 m-8 c-12">
                                <div class="center">
                                    <div class="title"><h2>DỰ TOÁN LÀM HÀNG</h2></div>
                                    <div class="code"><span class="label">Số: </span><span>{{entity.estimateCode}}</span></div>
                                    <div class="date"><span class="label">Ngày: </span><span>{{entity.estimateDate}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters" style="padding-top:10px;">
                            <div class="col l-12 m-12 c-12">
                                <div class="line"> 
                                    <label>Mã phiếu: </label>
                                    <div>{{entity.estimateCode}}</div>
                                </div>
                                <div class="line"> 
                                    <label>Người lập phiếu: </label>
                                    <div>{{entity.staff.fullName}}</div>
                                </div>
                                <div class="line-2">
                                    <div class="line"> 
                                        <label>Ghi chú: </label>
                                        <div>{{entity.note}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row no-gutters">
                            <div class="col l-12 m-12 c-12" style="padding-top:20px;">
                                <table class="table-1">
                                    <thead>
                                        <tr>
                                            <th style="width:80px;">STT</th>
                                            <th style="width:400px;">Số con't/Kiện hàng</th>
                                            <th style="width:120px;">Loại</th>
                                            <th style="width:150px;">Đơn giá</th>
                                            <th style="width:100px;">Vat</th>
                                            <th style="width:150px;">Thành tiền</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(item, index) in details" :key="'item-' + index">
                                        <tr>
                                            <td style="text-align: center; padding: 5px 10px; border: 1px solid #e0e0e0;"><span style="font-weight:bold;">{{romanize(index + 1)}}</span></td>
                                            <td style="padding: 5px 10px; border: 1px solid #e0e0e0;">
                                                <span v-if="item.key !== ''" style="font-weight:bold;">{{item.key}}</span>
                                                <span v-else style="font-weight:bold;">Phí làm hàng</span>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr v-for="(obj, i) in item.list" :key="'detail' + i">
                                            <td class="stt">{{i + 1}}</td>
                                            <td style="padding-left: 30px;">{{obj.fee.feeName}}</td>
                                            <td>{{ getFeeType(obj.type) }}</td>
                                            <td style="text-align: right;">{{formatNumber(obj.price)}}</td>
                                            <td style="text-align: center;">{{formatNumber(obj.vat)}}%</td>
                                            <td style="text-align: right;">{{formatNumber(obj.amount)}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td colspan="5" class="stt"><b>Tổng cộng</b></td>
                                            <td style="text-align: right; font-weight: bold;">{{ formatNumber(entity.pay) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col l-12 m-12 c-12">
                                <div style="display: flex;justify-content: space-between;padding: 20px 30px 50px 30px;">
                                    <div v-for="(item, index) in entity.approveList" :key="'approve-'+ index" style="display: flex; flex-direction: column; width: 40%; font-size: 17px; text-transform: none;">
                                        <div style="font-weight: bold; text-align: center;">
                                            {{ item.title }}
                                        </div>
                                        <div v-if="item.status == 2" style="color: #448aff; text-align: center;">
                                            Đã duyệt
                                        </div>
                                        <div v-else style="color: red; text-align: center;">
                                            Chưa duyệt
                                        </div>
                                        <div style="font-weight: bold; text-align: center; padding-top: 100px;">
                                            {{ item.staff.fullName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import orderEstimateService from '@/api/orderEstimateService';
    import messageBox from '@/utils/messageBox';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Dự toán làm hàng'
        },
        data() {
            return {
               title: 'Dự toán làm hàng',
               id: 0,
               submitted: false,
               loadding: false,
               details: [],
               entity: { id: 0, staff: { fullName: '' }, customer: { companyName: '' } },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getById(){
                this.setLoading(true);
                orderEstimateService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;

                        const details = this.groupBy(this.entity.details);
                        this.details = [];

                        Object.keys(details).forEach((item, index) => {
                            const objItem = { key: item, list: details[item] };
                            details[index] = details[item];
                            delete details[item];
                            this.details.push(objItem);
                        });
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            groupBy(list) {
                const groups = list.reduce((groups, item) => ({
                    ...groups,
                    [item.estimate.estimateCode]: [...(groups[item.estimate.estimateCode] || []), item]
                }), {});
                return groups;
            },

            romanize(num) {
                if (isNaN(num))
                    return NaN;
                var digits = String(+num).split(""),
                    key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                        "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                        "","I","II","III","IV","V","VI","VII","VIII","IX"],
                    roman = "",
                    i = 3;
                while (i--)
                    roman = (key[+digits.pop() + (i * 10)] || "") + roman;
                return Array(+digits.join("") + 1).join("M") + roman;
            },
        },
    })

</script>

<style scoped>
    .wrapper {
        margin: 0 auto;
        width: 1000px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 100px;
        width: 220px;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
    }
    .center .title h2 {
        font-size: 26px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 18px;
    }
    .label {
        font-weight: bold;
    }
    .approve {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 20px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 18px;
        font-weight: bold;
    }
    .line {
        display: flex;
        padding-top: 5px;
        align-items: center;
    }
    .line label {
        font-weight: bold;
        padding-right: 10px;
        font-size: 18px;
    }
    .line span{
        font-size: 18px;
        font-weight: bold;
    }
    .line div {
        font-size: 18px;
    }
    .line-item {
        padding-right: 5px;
    }
    .line-item::after {
        content: ',';
    }
    .line-item:last-child::after {
        content: '';
    }
    .line-2 {
        display: flex;
    }
    .line-2 .line:first-child {
        padding-right: 40px;
    }
    .line-3 {
        display: flex;
        justify-content: space-between;
    }
    .table-1 {
        width: 100%;
    }
    .stt {
        text-align: center;
    }
    .table-1 td, .table-1 th {
        font-size: 14px;
    }
    .table-1 {
        min-height: 200px !important;
    }
    .currency {
        text-align: right;
    }
    b {
        font-weight: bold;
        font-size: 14px;
    }
</style>
